<template>
  <div class="collapse-wrap">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    accordion: Boolean
  },
  computed: {
    $collapseItems() {
      return this.$children
    }
  },
  methods: {
    setActiveIndex(index) {
      if (this.accordion) {
        this.$children.forEach((item, i) => {
          if (i !== index) {
            item.isOpen = false;
          }
        });
      }
    },
  }
}
</script>

<style>
  :root {
    --color-black: #333;
  }
  .card-header-title {
    font-weight: 700;
    color: var(--color-black);
  }
  .collapse-item .card-header {
    display: flex;
    cursor: pointer;
  }
  .collapse-item .card-header .card-header-icon {
    margin-left: auto;
  }
  .collapse-item .card-content {
    /* padding: 0 1rem; */
    margin-top: -1rem; /* fix padding issue */
  }
  .collapse-item .card-content .content li {
    padding-left: 2rem;
    margin-bottom: 0;
    text-transform: lowercase;
  }
  .collapse-item .card-header-icon {
    transition: transform .377s ease;
  }
  .collapse-item.is-active > .card-header .card-header-icon {
    transform: rotate(90deg);
  }
</style>
