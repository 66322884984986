import Vue from 'vue'

// import Drawer from '../components/Drawer.vue'
import Collapse from '../components/Collapse.vue'
import CollapseItem from '../components/CollapseItem.vue'
// import Vue2TouchEvents from 'vue2-touch-events'
import VueTouch from 'vue-touch'
Vue.use(VueTouch, {name: 'v-touch'})

// Vue.use(Vue2TouchEvents) // 'use' for plugins
// Vue.component('drawer', Drawer)
Vue.component('Collapse', Collapse)
Vue.component('CollapseItem', CollapseItem)

const v1 = new Vue({
  delimiters: ['${', '}'],
  el: '#vue-main',
  data: {
    text: 'artifacial',
    drawerShow: false
  },
  methods: {
    touchHandler () {
      // var elem = document.getElementById('pull-menu')
      // style = window.getComputedStyle(elem)
      // height = style.getPropertyValue('height')
      // document.getElementById('pull-menu').style.height = height+1;
      // // this.text = this.text + "."
      // console.log(height)
      // alert("tap")
    }
  }
  // render: h => h(App)
})

const v2 = new Vue({
  delimiters: ['${', '}'],
  el: '#pull-menu',
  data: {
    // drawerShow: false
  },
  methods: {
    drawerToggles () {
      // this.drawerShow = !this.drawerShow
    }
  }
  // render: h => h(App)
})

const v3 = new Vue({
  // delimiters: ['${', '}'],
  el: '#drawer',
  data: {
    // drawerShow: false
  },
  methods: {
    // drawerToggles () {
      // this.drawerShow = !this.drawerShow
    // }
  }
  // render: h => h(App)
})

// export default {
//   data () {
//     return {
//       drawerShow: false
//     }
//   },
//   methods: {
//     drawerToggle () {
//       this.drawerShow = !this.drawerShow
//     }
//   },
//   components: { Drawer }
// }

// GRID TOGGLE
document.querySelector('.toggle-grid').addEventListener('click', function () {
  document.body.classList.toggle('grid')
})
// menu TOGGLE
document.querySelector('#menu-button').addEventListener('click', function () {
  document.querySelector('#pull-menu').classList.toggle('menu-on')
})

//  touch events
// document.querySelector('#vue-main').addEventListener('swipedown',function (e){
//   console.log(e.x);
//   console.log(e.y);
//   console.log(e.distance.x);
//   console.log(e.distance.y);
// });
